import { useEffect, useRef } from 'react';

import gsap from 'gsap/dist/gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useTranslation } from 'next-i18next';

gsap.registerPlugin(ScrollTrigger);

export const HomepageAbout: React.FC = () => {
  const { t } = useTranslation('common');
  const el = useRef(null);

  useEffect(() => {
    gsap.to('#about', {
      y: 0,
      opacity: 1,
      duration: 2,
      scrollTrigger: {
        scrub: true,
        start: 'top 100%',
        end: 'top 60%',
        trigger: '#about',
      },
    });
    gsap.to('#about-title', {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: { start: 'top 100%', end: 'top 40%', trigger: '#about', scrub: true },
    });
    gsap.to('#about-content-1', {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: { start: 'top 60%', end: 'top 30%', trigger: '#about', scrub: true },
    });
    gsap.to('#about-content-2', {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: { start: 'top 58%', end: 'top 26%', trigger: '#about', scrub: true },
    });
    gsap.to('#about-content-3', {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: { start: 'top 58%', end: 'top 20%', trigger: '#about', scrub: true },
    });
    gsap.to('#about-content-4', {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: { start: 'top 34%', end: 'top 4%', trigger: '#about', scrub: true },
    });
  }, []);

  return (
    <section
      ref={el}
      id="about"
      className="bg-gradient flex min-h-[100vh] w-full items-center overflow-hidden py-20 md:py-28 lg:py-36 ">
      <div className="container">
        <div className="grid gap-2 gap-x-16 text-white lg:grid-cols-4">
          <div id="about-title" className="col-span-4 pb-40 opacity-0 xl:col-span-2">
            <h2
              id="about-subtitle"
              className="text-heading-about mb-8 font-black"
              dangerouslySetInnerHTML={{ __html: t('pageHomepage:about.title') }}
            />

            <p
              className="text-md max-w-[500px] text-white"
              dangerouslySetInnerHTML={{ __html: t('pageHomepage:about.description') }}
            />
          </div>

          <div className="col-span-4 xl:col-span-2">
            <div className="grid grid-cols-2 gap-2">
              <div
                id="about-content-1"
                className="col-span-2 translate-y-[260px] bg-[#30273B] p-6 py-8 opacity-50 lg:p-8">
                <h3
                  className="text-heading-item mb-4 font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.fourth.title'),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.fourth.description'),
                  }}
                />
              </div>
              <div
                id="about-content-2"
                className="col-span-2 translate-y-[240px] bg-[#30273B] p-6 py-8 opacity-20 lg:col-span-1 lg:p-8">
                <h3
                  className="text-heading-item mb-8 font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.second.title'),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.second.description'),
                  }}
                />
              </div>
              <div
                id="about-content-3"
                className="col-span-2 translate-y-[300px] bg-[#30273B] p-6 py-8 opacity-40 lg:col-span-1 lg:p-8">
                <h3
                  className="text-heading-item mb-8 font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.third.title'),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('pageHomepage:about.content.third.description'),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-1 hidden xl:flex" />
          <div
            id="about-content-4"
            className="col-span-4 translate-y-[220px] gap-2 bg-[#30273B] p-6 py-8 opacity-70  lg:p-8 xl:col-span-3">
            <h3
              className="text-heading-item mb-4 font-semibold"
              dangerouslySetInnerHTML={{
                __html: t('pageHomepage:about.content.first.title'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('pageHomepage:about.content.first.description'),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
